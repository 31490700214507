<template>
  <div class="animated fadeIn">
    <LoadingWidget v-if="initStatus != 1" :status="initStatus" @init="init" />
    <b-card v-else>
      <template v-slot:header>
        <h5>Pemakaian</h5>
      </template>
      <p>Info! Dapat <span class="text-danger">dihapus</span> sebelum 2 hari. setalah itu dinyatakan sudah fix.</p>
      <b-row>
        <b-col md="6">
          <b-form-group label="Filter" label-for="filterInput" class="form-inline form-group-wrapper mb-3">
            <div class="d-flex search-input-wrapper">
              <b-form-input class="ml-2 mr-2" v-model="filter" name="filterTable" id="filterInput" type="search"
                placeholder="Type to search"></b-form-input>
              <!-- <b-button class="mr-2" variant="pgiBtn" name="searchTableBtn" :disabled="isBusy"
                                @click="onFilter(filter)">
                                <i class="fa fa-search"></i>
                            </b-button> -->
              <b-button variant="pgiBtn" name="resetTableBtn" :disabled="isBusy" @click="onReset()">Reset</b-button>
            </div>
          </b-form-group>
          <b-form-group class="form-inline filter-form-date" label-for="filterDate" label="Periode ">
            <div class="d-flex search-input-wrapper">
              <b-form-input v-model="input.filterFromDateTarik" name="filterFromDateTarik" id="filterDate"
                type="date"></b-form-input>
              <span class="ml-2 mr-2 d-flex align-items-center">s/d</span>
              <b-form-input v-model="input.filterToDateTarik" name="filterToDateTarik" type="date"></b-form-input>
            </div>
          </b-form-group>
        </b-col>
        <b-col>
          <div class="d-flex justify-content-end">
            <b-button class="" variant="pgiBtn" @click="$bvModal.show('m-create')">
              <i class="fa fa-plus"></i> Tambah Pemakaian
            </b-button>
          </div>
          <!-- <div class="d-flex justify-content-end py-2">
                    <b-button class="mr-2" variant="pgiBtn" >
                  <download-excel name="pemakaian.xls" :data="pemakaian" :fields="json_fields">
                    <i class="fa fa-download mr-2"></i>
                          Excel
                  </download-excel>
                </b-button>
          </div> -->
        </b-col>
      </b-row>
      <b-table bordered hover show-empty :busy="isBusy" :items="pemakaian" :fields="fields" responsive class="mb-3">
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong> Loading data...</strong>
          </div>
        </template>
        <template v-slot:empty>
          Tidak ada data Pemakaian.
        </template>
        <template v-slot:cell(kelengkapan)="row">
          <ul class="list-none-wrapper" v-if="row.item.kelengkapan.length > 0">
            <li v-for="kelengkapan in row.item.kelengkapan" v-bind:key="kelengkapan.id">
              <span>
                <i class="fa fa-check" v-if="(kelengkapan.isActive == true)"></i>
                <i class="fa fa-times" v-if="(kelengkapan.isActive == false)"></i>
                {{ kelengkapan.name }}
              </span>
            </li>
          </ul>
          <span v-else>-</span>
        </template>
        <template v-slot:cell(status)="row">
          <b-badge variant="success" v-if="row.item.status == true">Suksess</b-badge>
          <b-badge variant="warning" v-else>Menunggu Pembayaran</b-badge>
        </template>
        <template v-slot:cell(action)="row">
          <b-button size="sm" class="mr-3 table-button edit" v-b-tooltip.hover title="Hapus" name="pemakaianHapusBtn"
            @click="confirmHapus(row.item.id)">
            <i class="fa fa-trash text-danger"></i>
          </b-button>
        </template>
      </b-table>

      <div class="pagination-wrapper d-flex align-items-center justify-content-between">
        <b-input-group class="align-items-center tableShowItem">
          <span class="ml-2">{{ totalViewText }}</span>
        </b-input-group>
        <b-pagination class="mb-0" v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table"
          @input="onPageChange"></b-pagination>
      </div>
    </b-card>

    <!-- MODAL CREATE -->
    <b-modal id="m-create" size="lg" title="Input Data Pemakaian" no-close-on-backdrop no-close-on-esc hide-footer>
      <b-row>
        <b-col>
          <b-form-group label="Gudang">
            <multiselect v-model="form.pic" :options="gudang" :internal-search="true" :allow-empty="false"
              deselect-label="" select-label="" label="name" track-by="kode" placeholder="-- Pilih Gudang --" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="8">
          <b-form-group>
            <label>Barang</label>
            <multiselect v-model="idJenis" :options="jenisBarang" label="nama_barang" track-by="id"
              class="header-select mr-3" placeholder="Masukan Kode / Nama Barang" :searchable="true"
              :internal-search="false" :loading="isLoading" :clear-on-select="false" :show-no-results="false"
              @search-change="test" :disabled="(form.pic == null) ? true : false" />
          </b-form-group>
        </b-col>
        <b-col sm="">
          <b-form-group label="Stok Barang">
            <b-form-input id="stok" v-model="stok" type="text" readonly></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="4">
          <b-form-group label="qty">
            <b-form-input id="satuan" v-model="form.jumlah" type="number" min="0"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group label="Satuan">
            <b-form-input id="satuan" v-model="form.satuan" type="text" readonly></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="4" hidden="true">
          <b-form-group label="Harga" label-for="harga">
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text>Rp.</b-input-group-text>
              </b-input-group-prepend>
              <b-form-input v-model="form.harga" id="harga" placeholder="" required="">
              </b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group label="Total" label-for="total">
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text>Rp.</b-input-group-text>
              </b-input-group-prepend>
              <b-form-input v-model="form.total_harga" id="harga" placeholder="" required="" readonly></b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="6">
          <b-form-group label="Imei">
            <b-form-input id="imei" v-model="form.imei" type="text"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Detail">
            <b-form-input id="detail" v-model="form.detail_barang" type="text"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="6">
          <b-form-group label="Penerima Barang">
            <b-form-input id="penerima" v-model="form.pemakai" type="text"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Keperluan">
            <b-form-input id="keperluan" v-model="form.keperluan" type="text"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <div class="mt-4 text-right">
        <b-button variant="pgiBtn" @click="prepare('m-confirm-create')">
          Simpan
        </b-button>
      </div>
    </b-modal>

    <ModalConfirm id="m-confirm-create" title="Confirm Tambah Pemakaian" :isSubmitting="isSubmitting"
      desc="Apakah anda yakin untuk menambah pemakaian ini ?" @submit="onSubmit" />

    <ModalConfirm id="m-confirm-hapus" title="Confirm Hapus Pemakaian" :isSubmitting="isSubmitting"
      desc="Apakah anda yakin untuk menambah pemakaian ini ?" @submit="deletePemakaian()" />

  </div>
</template>

<script>
import Vue from "vue";
import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);
export default {
  name: 'master-jenis-barang',
  components: {
    Vue,
  },
  data() {
    return {
      initStatus: 0,
      isBusy: true,
      isLoading: false,
      isSubmitting: false,
      input: {
        show: 10
      },
      fields: [
        { key: "no", label: "No", sortable: true },
        { key: "tanggal", label: "Tanggal" },
        { key: "no_barang", label: "No Barang" },
        { key: "nama", label: "Nama Barang" },
        { key: "jumlah", label: "Qty" },
        { key: "total_harga", label: "Total Harga" },
        { key: "keperluan", label: "Keperluan" },
        { key: "pemakai", label: "Pemakai" },
        { key: "action", label: "Aksi" },
      ],
      json_fields: {
        "no": "no",
        "tanggal": "tanggal",
        "No Barang": "no_barang",
        "Nama Barang": "nama",
        "Qty": "jumlah",
        "Total Harga": "total_harga",
        "Keperluan": "keperluan",
        "Pemakai": "pemakai",
      },
      filter: null,
      options: [
        { value: 10, text: '10' },
        { value: 20, text: '20' },
        { value: 30, text: '30' },
        { value: 40, text: '40' },
        { value: 50, text: '50' },
      ],

      form: {
        tanggal: null,
        pic: null,
        nomer_barang: null,
        id_tipe: null,
        jumlah: null,
        satuan: null,
        harga: null,
        total_harga: null,
        imei: null,
        detail_barang: null,
        keperluan: null,
        pemakai: null,
        user_input: localStorage.getItem("name"),
        last_update: null
      },

      activeId: null,

      idJenis: null,
      stok: null,

      jenisBarang: [],
      gudang: [],
      pemakaian: [],

      currentPage: 1,
      perPage: null,
      rows: null,
      totalViewText: "",
      page: '',
      size: '',
      from: '',
      limit: '',
      loading: false,
      query: "",
    }
  },
  methods: {

    //----------------- Get Barang -----------------
    async test(query) {
      if (query.length > 2) {
        this.isLoading = true;
        // make API request to load websites

        let request = await this.$axios.get('api/internal-memo/memo/stock-barang-v3?kode_cabang=' + this.form.pic.kode + '&search=' + query)
        //let data = await request.json()
        if (request.data.data.length != 0) {
          for (var data of request.data.data) {
            data.nama_barang = data.nomer_barang + ' - ' + data.barang_tipe.tipe
          }
          this.jenisBarang = request.data.data
        }
        this.isLoading = false;
      }
    },

    //----------------- Fungsi Inisialisasi -----------------

    init() {
      this.initStatus = 0
      this.roleId = localStorage.getItem("role_id")


      if (this.roleId == 56 || this.roleId == 5 || this.roleId == 4) {
        let getCabang = localStorage.getItem("cabang")
        this.gudang = JSON.parse(getCabang)
      } else {
        this.gudang = [
          { kode: "0999", name: "Gudang Pusat" },
          { kode: "P00002", name: "Toilet Lt2" },
          { kode: "P00003", name: "Toilet Lt3" },
          { kode: "P00004", name: "Toilet Lt4" },
          { kode: "P00005", name: "Toilet Lt1" },
          { kode: "P00007", name: "HRD Recruitment" },
          { kode: "P00008", name: "Profesional Room" },
          { kode: "P00009", name: "Support" },
          { kode: "P00010", name: "HRD Payroll" },
          { kode: "P00011", name: "Meeting Audit" },
          { kode: "P00012", name: "GM Support" },
          { kode: "P00013", name: "IT Development" },
          { kode: "P00014", name: "IT Support" },
          { kode: "P00015", name: "Loyal Room" },
          { kode: "P00016", name: "Desk Collection" },
          { kode: "P00017", name: "Focus Room" },
          { kode: "P00018", name: "GM Audit" },
          { kode: "P00019", name: "GM Bisnis" },
          { kode: "P00020", name: "Area Manager Fathur" },
          { kode: "P00021", name: "Area Manager Faiz" },
          { kode: "P00022", name: "Area Manager Indah" },
          { kode: "P00023", name: "Area Manager Eka" },
          { kode: "P00024", name: "Internal Audit" },
          { kode: "P00025", name: "Bisnis" },
          { kode: "P00026", name: "General Affair" },
          { kode: "P00028", name: "Keuangan" },
          { kode: "P00029", name: "GM General Affair" },
          { kode: "P00030", name: "Wakil Direktur" },
          { kode: "P00031", name: "Ruang CCTV" },
        ]
      }
      let getPemakaian = this.$axios.get("api/transaksi/pemakaian/paginate").then(response => {
        // this.pemakaianAll = response.data
        // this.pemakaian = response.data
        let datas = response.data.data
        let i = 1
        for (var data of datas.data) {
          // var tgl = new Date(data.tanggal)
          // console.log(tgl);
          // this.kalkulasiDate(data.tanggal)
          this.pemakaian.push(
            {
              no: i,
              id: data.id,
              tanggal: data.tanggal,
              no_barang: data.nomer_barang,
              nama: data.barang_tipe.tipe,
              jumlah: data.jumlah,
              total_harga: "Rp. " + data.total_harga.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,"),
              keperluan: data.keperluan,
              pemakai: data.pemakai,
              noEdit: this.kalkulasiDate(data.tanggal)
            }
          )
          i++
        }

        this.perPage = datas.per_page
        this.size = this.perPage
        this.from = datas.from
        this.limit = datas.to
        this.loading = false

        this.updateTotalItem(datas.total)
        let msg = this.$helper.getFlash()
        this.$helper.toastSucc(this, msg)
      })

      Promise.all([getPemakaian]).then(() => {
        this.initStatus = 1
        let msg = this.$helper.getFlash()
        this.$helper.toastSucc(this, msg)
        this.toggleBusy()
      })
        .catch(error => {
          this.initStatus = -1
        })
    },

    getPemakaian() {
      this.toggleBusy()
      //  if (this.filter.search != null) this.query = this.query + "&search=" + this.filter.search
      this.$axios.get("api/transaksi/pemakaian/paginate" + this.query).then(response => {

        this.pemakaian = []
        let datas = response.data.data

        let i = datas.from
        for (var data of datas.data) {

          this.pemakaian.push(
            {
              no: i,
              id: data.id,
              tanggal: data.tanggal,
              no_barang: data.nomer_barang,
              nama: data.barang_tipe.tipe,
              jumlah: data.jumlah,
              total_harga: "Rp. " + data.total_harga.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,"),
              keperluan: data.keperluan,
              pemakai: data.pemakai,
              noEdit: this.kalkulasiDate(data.tanggal)
            }
          )
          i++
        }

        this.perPage = datas.per_page
        this.size = this.perPage
        this.from = datas.from
        this.limit = datas.to
        this.loading = false

        this.toggleBusy()
        this.updateTotalItem(datas.total)
        let msg = this.$helper.getFlash()
        this.$helper.toastSucc(this, msg)

      })
        .catch(error => {
          this.$helper.parseError(this, error)
          this.toggleBusy()
          this.loading = false
        })
    },


    //----------------- Fungsi Pagination, Sorting, dan Filtering -----------------

    onPageChange() {
      if (this.loading == false) {

        this.page = this.currentPage
        this.query = "?page=" + this.page

        this.getPemakaian()
      }
    },

    onLimitChange() {
      this.perPage = this.input.show
      this.onPageChange()
    },

    onFilter(letters) {

      if (this.filter == null) {
        this.$helper.toastErr(this, "Silakan isi nama jenis barang terlebih dahulu!")
      } else {
        let filteredArray = []

        for (var x of this.pemakaianAll) {
          if (x.name.toLowerCase().includes(letters.toLowerCase())) {
            filteredArray.push(x)
          }
        }

        this.pemakaian = filteredArray
        this.onFiltered(this.pemakaian)
      }
    },

    onFiltered(filteredItems) {
      this.rows = filteredItems.length
      this.currentPage = 1
      this.perPage = this.input.show
      let prevLimit = null
      let limit = null

      prevLimit = (this.currentPage - 1) * this.rows + 1
      limit = (this.currentPage - 1) * this.rows + this.rows
      if (limit > this.input.show) limit = this.input.show
      this.totalViewText = "Menampilkan " + prevLimit + " - " + limit + " dari " + this.rows + " entri"
    },

    onReset() {
      this.filter = ""
      this.toggleBusy()
      this.getPemakaian()
    },


    //----------------- Fungsi Pindah Halaman dan Fungsi Lain -----------------

    toggleBusy() {
      if (this.isBusy == true) this.isBusy = false
      else this.isBusy = true
    },

    updateTotalItem(total) {
      this.perPage = this.size
      this.rows = total
      if (this.limit > this.rows) limit = this.rows
      this.totalViewText = "Menampilkan " + this.from + " - " + this.limit + " dari " + this.rows + " entri"
    },

    prepare(modalToShow) {

      if (this.form.pic == null || this.form.pic == "") {
        return this.$helper.toastErr(this, "Mohon pilih Gudang!")
      }
      if (this.form.id_tipe == null || this.form.id_tipe == "") {
        return this.$helper.toastErr(this, "Mohon pilih Jenis Barang!")
      }
      if (this.form.jumlah == null || this.form.jumlah == "" || this.form.jumlah == 0) {
        return this.$helper.toastErr(this, "Mohon masukan jumlah barang!")
      }
      if (this.stok == 0) {
        return this.$helper.toastErr(this, "Stok Barang Kosong!")
      }
      if (this.form.jumlah > this.stok) {
        return this.$helper.toastErr(this, "Stok Barang tidak mencukupi!")
      }

      this.$bvModal.show(modalToShow)

    },

    onSubmit() {
      let formSubmit = this.form
      var currentDateWithFormat = new Date().toJSON().slice(0, 10).replace(/-/g, '-');
      formSubmit.tanggal = currentDateWithFormat
      formSubmit.last_update = currentDateWithFormat
      formSubmit.pic = this.form.pic.kode
      if (this.form.harga != 0) { formSubmit.harga = this.$helper.removeThousandSeparator(this.form.harga) }
      if (this.form.total_harga != 0) { formSubmit.total_harga = this.$helper.removeThousandSeparator(this.form.total_harga) }
      this.$axios.post('api/transaksi/pemakaian', formSubmit).then((response) => {

        this.idJenis = null
        this.stok = 0
        this.form = {
          tanggal: null,
          pic: null,
          nomer_barang: null,
          id_tipe: null,
          jumlah: null,
          satuan: null,
          harga: null,
          total_harga: null,
          imei: null,
          detail_barang: null,
          keperluan: null,
          pemakai: null,
          user_input: localStorage.getItem("name"),
          last_update: null
        },

          this.$bvModal.hide("m-create")
        this.$bvModal.hide("m-confirm-create")
        this.$helper.toastSucc(this, "Barang berhasil ditambahkan!")

        this.getPemakaian()
      })
    },

    confirmHapus(id) {
      this.activeId = id
      this.$bvModal.show("m-confirm-hapus")
    },

    deletePemakaian() {
      this.$axios.delete("api/transaksi/pemakaian/" + this.activeId).then(() => {
        this.activeId = null
        this.$bvModal.hide("m-confirm-hapus")
        this.$helper.toastSucc(this, "data Pemakaian berhasil dihapus!")
        this.getPemakaian()
      })
    },

    kalkulasiDate(date) {

      var tanggal = new Date(date)
      var currentTime = new Date()
      tanggal.setDate(tanggal.getDate() + 2);

      if (tanggal < currentTime) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {

    'idMerk': function (id) {
      this.tipeBarang = []
      this.form.id_tipe = null
      this.form.satuan = null
      if (id != null) {
        this.$axios.get("api/admin/barang-tipe/all" + "?id_merk=" + id.id).then(response => {
          let datas = response.data
          for (var data of datas.data) {
            this.tipeBarang.push({
              id: data.id,
              tipe: data.tipe,
              satuan: data.satuan
            })
          }

          if (this.isBusy == true) this.toggleBusy()
        })
          .catch(error => {
            this.$helper.parseError(this, error)
            if (this.isBusy == true) this.toggleBusy()
          })
      }

    },

    "form.harga": function (newValue) {

      let cleanValue = this.$helper.changeToValue(newValue);
      let harga = (this.form.jumlah == null) ? 1 : parseInt(this.form.jumlah) * parseInt(cleanValue)

      const result = cleanValue.toString().replace(/(?!^-)[^0-9]/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      this.$nextTick(() => this.form.harga = result);

      const total = harga.toString().replace(/(?!^-)[^0-9]/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      this.$nextTick(() => this.form.total_harga = total);
    },

    "form.jumlah": function (newValue) {
      let cleanValue = 0
      if (this.form.harga != null) {
        cleanValue = this.$helper.changeToValue(this.form.harga);
      }
      let harga = newValue * parseInt(cleanValue)
      const total = harga.toString().replace(/(?!^-)[^0-9]/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      this.$nextTick(() => this.form.total_harga = total);

    },

    "idJenis": function (newVal) {
      if (newVal.jumlah_stok < 0) {
        this.stok = 0
      } else {
        this.stok = newVal.jumlah_stok
      }
      this.form.id_tipe = newVal.id_tipe
      this.form.harga = newVal.barang_tipe.harga.toString()
      this.form.satuan = newVal.satuan
      this.form.imei = newVal.imei
      this.form.detail_barang = newVal.detail_barang
      this.form.nomer_barang = newVal.nomer_barang
    }

  },
  created() {
    this.init()
  }
}
</script>
